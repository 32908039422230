<template>
  <div class="container">
    <div
      class="is-flex is-align-items-center is-justify-content-space-between apps-header"
    >
      <p class="has-text-weight-bold is-size-4 mb-0 profile-title">
        {{ $t("af:applications.title") }}
      </p>

      <b-button
        type="is-blue is-rounded"
        size="is-small"
        icon-pack="fas"
        icon-left="plus-circle"
        v-if="!hasActiveApplication || forceBackButton"
        @click="backToOffers"
      >
        {{ $t("af:applications.no_result.button.back_to_offers") }}
      </b-button>
    </div>

    <OfferLoading v-if="!loaded" class="mt-6"></OfferLoading>

    <div
      v-if="
        applications.length === 0 && loaded && isBrand(CUSTOM_APPEARANCE.MBH)
      "
      class="info-box mt-6 is-flex"
    >
      <div class="mr-4">
        <b-icon icon="info-circle" size="is-medium" type="is-info" />
      </div>
      <div :style="{ wordBreak: 'break-word' }">
        <p class="has-text-weight-bold">
          {{ $t("af:applications.no_result.title") }}
        </p>
        <p>{{ $t("af:applications.no_result.subtitle") }}</p>
      </div>
    </div>

    <div
      class="af-application-cta"
      v-if="
        applications.length === 0 && loaded && !isBrand(CUSTOM_APPEARANCE.MBH)
      "
    >
      <p class="has-text-weight-bold is-size-5">
        {{ $t("af:applications.no_result.title") }}
      </p>
      <p>{{ $t("af:applications.no_result.subtitle") }}</p>

      <b-button
        type="is-blue"
        icon-pack="fas"
        icon-left="plus-circle"
        size="is-medium"
        @click="backToOffers"
      >
        {{ $t("af:applications.no_result.button.back_to_offers") }}
      </b-button>

      <img
        class="af-application-cta__image"
        :src="require('@/assets/images/application-empty-state.svg')"
        alt="InstaCash logo"
      />
    </div>

    <div
      class="af-application-card-mobile mt-6"
      v-for="application in applications"
      :key="application.data.id + 'mob'"
    >
      <div class="offer-card py-4 px-4">
        <div
          class="is-flex is-justify-content-space-between offer-card-content"
        >
          <div class="is-flex offer-card-title" :style="{ gap: '8px' }">
            <PartnerLogo hide-name :offerId="application.offer.data.id" />
            <p class="has-text-weight-bold offer-name">
              {{ application.offer.data.name }}
            </p>
          </div>

          <div class="is-flex is-align-items-center details-holder">
            <div class="offer-detail mr-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:application.offer.details.amount") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ monify(application.offer.calculation.amount) }}
                {{ $t("af:application.offer.details.amount_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:application.offer.details.duration") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ application.offer.calculation.duration }}
                {{ $t("af:application.offer.details.duration_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:application.offer.details.thm") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ application.offer.calculation.thm.toFixed(2)
                }}{{ $t("af:application.offer.details.thm_unit") }}
              </p>
            </div>
          </div>

          <div class="is-flex is-align-items-center details-holder">
            <div class="offer-detail mr-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:applications.label.total_cost") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ monify(application.offer.calculation.totalPayment) }}
                {{ $t("af:application.offer.details.amount_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:applications.label.monthly_payment") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ monify(application.offer.calculation.monthlyPayment) }}
                {{ $t("af:application.offer.details.amount_unit") }}
              </p>
            </div>

            <div class="offer-detail mx-1">
              <p class="is-uppercase has-text-grey-icon mb-1 detail-label">
                {{ $t("af:application.offer.details.interest_rate") }}
              </p>
              <p class="has-text-weight-bold detail-amount">
                {{ application.offer.calculation.rate.toFixed(2)
                }}{{ $t("af:application.offer.details.interest_rate_unit") }}
              </p>
            </div>
          </div>

          <div>
            <ApplicationFlowNavigator
              v-if="
                application.data.currentPosition !== FLOW_POSITION.INTRO &&
                application.data.currentStatus !== LOAN_STATUS.CLOSED &&
                application.data.currentStatus !== LOAN_STATUS.COMPLETED
              "
              class="af-application-card__navigator"
              :current-step-index="application.data.currentFlowIndex"
              :flow-position="application.data.currentPosition"
              :application-id="application.data.id"
              :steps="application.offer.data.flowConfig.steps"
              :without-buttons="true"
              :is-online="application.offer.data.online"
              :has-scoring="application.offer.data.scoringLive"
            />
          </div>
          <b-button
            v-if="
              application.data.currentStatus !== FLOW_POSITION.CLOSED &&
              application.data.currentStatus !== LOAN_STATUS.COMPLETED
            "
            id="profileContinueApplication"
            type="is-blue"
            expanded
            class="has-text-weight-medium mt-4 af-application-card__continue_button"
            @click="continueLoanApplication"
            >{{ $t("af:applications.button.continue") }}</b-button
          >
          <b-button
            v-if="
              application.data.currentStatus !== FLOW_POSITION.CLOSED &&
              application.data.currentStatus !== LOAN_STATUS.BANK_RESULT &&
              application.data.currentStatus !== LOAN_STATUS.COMPLETED &&
              !application.data.facekomRecords[0]
            "
            id="profileCancelApplication"
            type="is-danger"
            expanded
            class="has-text-weight-medium mt-4 af-application-card__close_button"
            @click="closeApplicationConfirm(application.data.id)"
            >{{ $t("af:applications.button.close") }}</b-button
          >
          <ApplicationFinalDocuments
            v-if="
              application.data.currentStatus === LOAN_STATUS.COMPLETED ||
              application.data.currentStatus === LOAN_STATUS.CLOSED
            "
            :documents="application.data.documents"
            class="af-application-card__documents_button"
          >
          </ApplicationFinalDocuments>

          <!--          <OfferDetailsModal-->
          <!--            :offer="application.offer"-->
          <!--            :button-text="$t('af:applications.button.details')"-->
          <!--            class="af-application-card__details_button"-->
          <!--          />-->
        </div>
      </div>
    </div>

    <div
      class="af-application-card mt-6"
      v-for="application in applications"
      :key="application.data.id"
    >
      <div class="af-application-card__top">
        <div class="af-application-card__header mb-4">
          <div class="logo-container">
            <PartnerLogo :offerId="application.offer.data.id" />
          </div>

          <span
            class="application-status-badge application-status-badge--signready"
          >
            <span class="has-text-white">{{
              $t(statuses[application.data.currentStatus])
            }}</span>
          </span>
        </div>

        <div class="af-application-card__title">
          <p class="has-text-weight-bold offer-name">
            {{ application.offer.calculation.offerName }}
          </p>
        </div>

        <div class="af-application-card__details">
          <div class="offer-info">
            <div class="offer-info__box separator ml-0">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:applications.label.amount") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{ monify(application.offer.calculation.amount) }} Ft</span
              >
            </div>

            <div class="offer-info__box separator">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:applications.label.total_cost") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{
                  monify(application.offer.calculation.totalPayment)
                }}
                Ft</span
              >
            </div>

            <div class="offer-info__box separator">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:applications.label.duration") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{ monify(application.offer.calculation.duration) }}
                {{ $t("af:applications.label.duration_unit") }}</span
              >
            </div>

            <div class="offer-info__box separator">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:applications.label.monthly_payment") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{
                  monify(
                    application.offer.calculation.monthlyPayment +
                      (application.offer.calculation.ppiAmount
                        ? application.offer.calculation.ppiAmount
                        : 0)
                  )
                }}
                Ft</span
              >
            </div>

            <div class="offer-info__box separator">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:applications.label.thm") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{ roundTwoDigits(application.offer.calculation.thm)
                }}{{ $t("af:applications.label.thm_unit") }}</span
              >
            </div>

            <div class="offer-info__box separator">
              <p class="offer-label is-uppercase mb-1 has-text-grey-icon">
                {{ $t("af:application.offer.details.interest_rate") }}
              </p>
              <span class="has-text-weight-medium is-size-5"
                >{{ roundTwoDigits(application.offer.calculation.rate)
                }}{{
                  $t("af:application.offer.details.interest_rate_unit")
                }}</span
              >
            </div>

            <template v-if="isBrand(!CUSTOM_APPEARANCE.MBH)">
              <div class="offer-info__box has-text-grey-icon separator">
                <p class="offer-label is-uppercase mb-1">
                  {{ $t("af:applications.label.application_time") }}
                </p>
                <p class="offer-label--active">
                  <b-icon icon-pack="fa" icon="stopwatch"></b-icon>
                  {{ formatSeconds(application.offer.data.applicationTime) }}
                </p>
              </div>

              <div class="offer-info__box has-text-grey-icon separator">
                <p class="offer-label is-uppercase mb-1">
                  {{ $t("af:applications.label.application_mode") }}
                </p>
                <p
                  v-if="application.offer.data.online"
                  class="offer-label--active"
                >
                  <b-icon icon-pack="fa" icon="couch"></b-icon>
                  {{ $t("af:applications.label.application_mode.online") }}
                </p>
                <p v-else>
                  <b-icon icon-pack="fa" icon="briefcase"></b-icon>
                  {{ $t("af:applications.label.application_mode.offline") }}
                </p>
              </div>

              <div class="offer-info__box has-text-grey-icon separator">
                <p class="offer-label is-uppercase mb-1">
                  {{ $t("af:applications.label.prints_needed") }}
                </p>
                <p>
                  <b-icon icon-pack="fa" icon="leaf"></b-icon>
                  {{ application.offer.data.printsNeeded }}
                  {{ $t("af:applications.label.prints_needed_unit") }}
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="af-application-card__footer">
        <div class="af-application-card__progress">
          <ApplicationFlowNavigator
            v-if="
              application.data.currentPosition !== FLOW_POSITION.INTRO &&
              application.data.currentStatus !== LOAN_STATUS.CLOSED &&
              application.data.currentStatus !== LOAN_STATUS.COMPLETED
            "
            class="af-application-card__navigator"
            :current-step-index="application.data.currentFlowIndex"
            :flow-position="application.data.currentPosition"
            :application-id="application.data.id"
            :steps="application.offer.data.flowConfig.steps"
            :without-buttons="true"
            :is-online="application.offer.data.online"
            :has-scoring="application.offer.data.scoringLive"
          />
        </div>
        <div class="af-application-card__actions">
          <b-button
            v-if="
              application.data.currentStatus !== FLOW_POSITION.CLOSED &&
              application.data.currentStatus !== LOAN_STATUS.COMPLETED
            "
            type="is-blue"
            expanded
            class="has-text-weight-medium mt-4 af-application-card__continue_button"
            @click="continueLoanApplication"
            >{{ $t("af:applications.button.continue") }}</b-button
          >
          <b-button
            v-if="
              application.data.currentStatus !== FLOW_POSITION.CLOSED &&
              application.data.currentStatus !== LOAN_STATUS.BANK_RESULT &&
              application.data.currentStatus !==
                LOAN_STATUS.PURCHASE_BANK_RESULT &&
              application.data.currentStatus !== LOAN_STATUS.COMPLETED &&
              !application.data.facekomRecords[0]
            "
            type="is-danger"
            expanded
            class="has-text-weight-medium mt-4 af-application-card__close_button"
            @click="closeApplicationConfirm(application.data.id)"
            >{{ $t("af:applications.button.close") }}</b-button
          >

          <ApplicationFinalDocuments
            v-if="
              application.data.currentStatus === LOAN_STATUS.COMPLETED ||
              application.data.currentStatus === LOAN_STATUS.CLOSED
            "
            :documents="application.data.documents"
            class="af-application-card__documents_button"
          >
          </ApplicationFinalDocuments>

          <!--          <OfferDetailsModal-->
          <!--            :offer="application.offer"-->
          <!--            :button-text="$t('af:applications.button.details')"-->
          <!--            class="af-application-card__details_button"-->
          <!--          />-->
        </div>
      </div>
    </div>
    <div v-if="applications.length > 0 && loaded" class="info-box mt-6 is-flex">
      <div class="mr-4">
        <b-icon icon="info-circle" size="is-medium" type="is-info" />
      </div>
      <div :style="{ wordBreak: 'break-word' }">
        <application-template
          template="PROFILE_MORTGAGE_APPLICATIONS_BOTTOM_INFO"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  creditEnabled,
  formatSeconds,
  isBrand,
  monify,
  roundTwoDigits,
} from "@/utils/util";
import {
  CUSTOM_APPEARANCE,
  FLOW_POSITION,
  LOAN_STATUS,
  SCORE_DECISION,
  CLOSEABLE_FLOW_POSITIONS,
  APPLICATION_MODE,
} from "@/utils/const";
import i18n from "@/plugins/i18n";
import ApplicationTemplate from "@/components/ApplicationTemplate.vue";

export default {
  name: "UserApplications",
  title: "af:page.user_applications.title",
  components: {
    ApplicationTemplate,
    // OfferDetailsModal: () =>
    //   import("@/components/offers/OfferDetailsModal.vue"),
    ApplicationFlowNavigator: () =>
      import("@/components/applicationflow/ApplicationFlowNavigator.vue"),
    PartnerLogo: () => import("@/components/PartnerLogo"),
    OfferLoading: () => import("@/components/offers/OfferLoading.vue"),
    ApplicationFinalDocuments: () =>
      import("@/components/applications/ApplicationFinalDocuments"),
  },
  async mounted() {
    if (!this.creditEnabled) {
      await this.$router.push("/user/mortgage-applications");
    }
    await this.loadApplications();
  },
  methods: {
    isBrand,
    async loadApplications() {
      await this.$store.dispatch("loanApplication/fetchMyApplications");
      let applications =
        this.$store.getters["loanApplication/getLoanApplications"];

      await this.$store.dispatch(
        "offers/fetchRawOfferDatas",
        applications.map((application) => application.offerId)
      );
      let offers = this.$store.getters["offers/rawOffers"];

      this.applications = [];
      for (const application of applications) {
        await this.$store.dispatch(
          "loanApplication/fetchBaseData",
          application.id
        );
        let baseData = this.$store.getters["loanApplication/baseData"](
          application.id
        );

        let scoringDecision = await this.$store.dispatch(
          "loanApplication/fetchAndGetScoringDecision",
          application.id
        );

        this.applications.push({
          data: application,
          offer: {
            data: offers.find((offer) => offer.id === application.offerId),
            calculation: baseData,
          },
          scoringDecision: scoringDecision,
        });
      }

      this.applications.sort(
        (a, b) => new Date(b.data.created) - new Date(a.data.created)
      );
      this.loaded = true;
    },
    continueLoanApplication() {
      this.$router.push({ path: "/application" });
    },
    backToOffers() {
      this.$store.dispatch(
        "profile/updateApplicationMode",
        APPLICATION_MODE.LOAN
      );

      this.$router.push({ path: "/offers" });
    },
    closeApplicationConfirm(id) {
      this.$buefy.dialog.confirm({
        message: i18n.t("af:applications.close_confirm.button.message"),
        cancelText: i18n.t("af:applications.close_confirm.button.cancel"),
        confirmText: i18n.t("af:applications.close_confirm.button.confirm"),
        type: "is-success",
        onConfirm: () => this.close(id),
      });
    },
    async close() {
      try {
        await this.$store.dispatch("loanApplication/close");
        this.forceBackButton = true;
        await this.$store.dispatch("profile/updateStatus", {
          status: "PRELIMINARY",
          result: "SUCCESS",
        });
        await this.loadApplications();

        this.$buefy.toast.open({
          message: i18n.t("af:applications.message.successful_close"),
          type: "is-success",
        });

        await this.$store.dispatch("purchase/hasActivePurchase");
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("af:applications.message.failed_close"),
          type: "is-danger",
        });
      }
    },
  },
  computed: {
    CUSTOM_APPEARANCE() {
      return CUSTOM_APPEARANCE;
    },
    ...mapGetters({
      hasActiveApplication: "profile/hasActiveApplication",
    }),
    creditEnabled: () => creditEnabled(),
  },
  data() {
    return {
      SCORE_DECISION,
      CLOSEABLE_FLOW_POSITIONS,
      applications: [],
      loaded: false,
      documentsLoaded: false,
      formatSeconds,
      monify,
      roundTwoDigits,
      FLOW_POSITION,
      LOAN_STATUS,
      statuses: {
        DATA_REGISTRY: "af:applications.statuses.data_registry",
        DOC_UPLOAD_STARTED: "af:applications.statuses.document_upload_started",
        DOC_UPLOADED: "af:applications.statuses.document_uploaded",
        KYC: "af:applications.statuses.kyc",
        FEC: "af:applications.statuses.fec",
        CONTRACT_CHECK: "af:applications.statuses.contract_check",
        CONTRACT_SIGN: "af:applications.statuses.contract_sign",
        CONTRACT_SIGNED: "af:applications.statuses.contract_signed",
        UNDER_REVIEW: "af:applications.statuses.under_review",
        SCORING: "af:applications.statuses.scoring",
        OFFLINE_UNDER_REVIEW: "af:applications.statuses.offline_under_review",
        BANK_RESULT: "af:applications.statuses.bank_result",
        OFFLINE_BANK_RESULT: "af:applications.statuses.offline_bank_result",
        PURCHASE_BANK_RESULT: "af:applications.statuses.purchase_bank_result",
        COMPLETED: "af:applications.statuses.completed",
        CLOSED: "af:applications.statuses.closed",
      },
      forceBackButton: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.navigator-container {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.af-application-card-mobile {
  display: block;
}

.af-application-cta {
  margin-top: 4rem;
  background: #f3f5f9;
  border: 1px solid #dfe6ef;
  border-radius: 24px;
  padding: 22px 25px 0;
  position: relative;

  &__image {
    display: block;
    margin: 0 auto -60px auto;
  }
}

.af-application-card {
  background: #ffffff;
  border: 1px solid #dfe6ef;
  box-shadow: 0 5px 30px rgba(48, 52, 62, 0.1);
  border-radius: 24px;
  display: none;

  &__top {
    padding: 24px;
  }

  &__footer {
    padding: 0 24px;
  }

  &__header {
    display: flex;
    flex-direction: column;

    .logo-container {
      order: 2;
    }

    .application-status-badge {
      order: 1;
      font-size: 14px;
      padding: 4px 1rem;
      border-radius: 100px;
      align-self: flex-start;
      margin-bottom: 1rem;

      &--signready {
        background: $green;
      }
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__details {
    .offer-info {
      display: flex;
      flex-wrap: wrap;

      .offer-label {
        font-size: 10px;

        &--active {
          color: $green !important;
        }

        &--green {
          color: $green !important;
        }

        &--red {
          color: $red !important;
        }

        &--blue {
          color: $blue !important;
        }
      }

      .offer-info__box {
        width: 50%;
        position: relative;
        margin-bottom: 1rem;

        p:last-child {
          font-size: 14px;
        }
      }
    }

    .separator:after {
      content: "";
      position: absolute;
      width: 70%;
      height: 1px;
      background: #dfe6ef;
      top: 120%;
      right: -1.5rem;
      transform: translateX(-65%);
    }

    div.separator:last-child:after {
      display: inherit;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .af-application-card__navigator {
      border-top: 1px solid #dfe6ef;
    }

    .af-application-card__actions {
      border-top: 1px solid #dfe6ef;
      font-size: 14px;
      margin-bottom: 1rem;
    }

    .af-application-card__close_button {
      margin-bottom: 1rem;
    }
  }
}

.info-box {
  padding: 24px;
  background-color: #e6e9f0;
  border-radius: 24px;
  color: #203667;
}

@media only screen and (min-width: 769px) {
  .af-application-card-mobile {
    display: none;
  }

  .af-application-card {
    padding: 32px;
    display: block;
    &__top {
      padding: 0;
    }
    &__footer {
      padding: 0 !important;
    }
    &__actions {
      margin-bottom: 0 !important;
    }

    &__header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .logo-container {
        order: 1;
      }

      .application-status-badge {
        margin-bottom: 0;
      }
    }

    &__title {
      margin-bottom: 3rem;
    }

    &__details {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;
      margin-top: -1rem;

      .offer-info {
        display: flex;
        flex-wrap: nowrap;

        .offer-info__box {
          width: inherit;
          position: relative;

          p:last-child {
            font-size: 14px;
          }
        }
      }

      .offer-info__box {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      .separator:after {
        content: "";
        position: absolute;
        height: 70%;
        width: 1px;
        background: #dfe6ef;
        top: 50%;
        right: -1.5rem;
        transform: translateY(-50%);
      }

      div.separator:last-child:after {
        display: none;
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      padding: 0 32px;

      .af-application-card__progress {
        width: 80%;
      }

      .af-application-card__navigator {
        border: none;
      }

      .af-application-card__actions {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        border: none;
        margin-bottom: 1rem;
      }

      .af-application-card__details_button {
        order: 1;
        margin: auto 0;
        width: 100%;
      }

      .af-application-card__documents_button {
        order: 2;
        margin-right: 1rem;
      }

      .af-application-card__close_button {
        order: 3;
        margin-right: 1rem;
      }

      .af-application-card__continue_button {
        order: 4;
      }
    }
  }
}

.mbh {
  .af-application-card {
    &__footer {
      flex-direction: column;
    }
  }
  .af-application-card__progress {
    width: 100%;
  }
  .af-application-card__actions {
    align-self: end;
  }

  .separator:after {
    background: none;
  }

  .offer-label {
    font-size: 13px !important;
    color: #616161;
  }

  .application-status-badge {
    order: 1;
    font-size: 18px;
    padding: 4px 1rem;
    border-radius: 100px;
    align-self: flex-start;
    margin-bottom: 1rem;

    background: none;

    .has-text-white {
      color: #203667 !important;
      font-weight: bold;
    }

    &--signready {
      background: none;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .af-application-cta {
    position: absolute;
    width: 100%;
    top: 20vh;
    padding: 44px 50px;

    &__image {
      position: absolute;
      bottom: 4px;
      right: 20px;
    }
  }

  .mbh {
    .profile-title {
      font-size: 2rem !important;
    }
    .apps-header {
      flex-direction: row !important;
    }
  }
}

.offer-card {
  width: 100%;
  margin: 0 auto;
  position: relative;

  background: #ffffff;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .offer-detail {
    background: #f3f5f9;
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;

    p:first-of-type {
      font-size: 10px;
    }

    p:last-of-type {
      font-size: 12px;
    }
  }
}

.offer-card-title {
  flex-direction: row;
}

.offer-card-content {
  flex-direction: column;
}

.details-holder {
  justify-content: space-between;
}

.mbh {
  .offer-detail {
    background: none;
    text-align: left;
  }

  .offer-card {
    max-width: 660px;
    border-radius: 32px;
    padding: 16px !important;

    &__label {
      left: 32px;
    }
  }

  .has-text-grey-icon {
    color: #616161 !important;
    font-size: 13px !important;
  }

  .detail-amount {
    color: #424242 !important;
    font-size: 14px !important;
  }

  .detail-label {
    font-size: 11px !important;
  }

  .offer-name {
    font-size: 20px;
    color: #051c3a;
    line-height: 28px;
  }

  .apps-header {
    flex-direction: column;
    gap: 8px;
  }
}
</style>
